.home {
  display: flex;
  flex-direction: column;
  position: relative;

  &-container {
    position: relative;
    height: rem(380);
    width: 100%;
  }

  &-headerTitle {
    color: $light-color;
    margin-top: rem(60);
    z-index: 2;
  }

  &-image {
    height: rem(359);
    width: rem(242);
    position: absolute;
    top: rem(14);
    clip: rect(rem(130), rem(170), rem(230), rem(70));
    right: 0;
    z-index: -99;

    &:hover {
      clip: auto;
    }
  }

  &-headerSubtitle {
    width: rem(242);
    color: $dark-color;
    margin-bottom: rem(31);
    align-self: flex-end;
    z-index: 0;
  }

  &-author {
    font-weight: 700;
  }
}
