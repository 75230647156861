.heading {
  &-1 {
    font-size: rem(48);
    line-height: rem(40);
    font-weight: 600;
  }

  &-2 {
    font-size: rem(48);
    line-height: rem(40);
    font-weight: lighter;
  }
}
