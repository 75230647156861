//COLORS
$primary-color: #d2d9da;
$dark-color: #000000;
$light-color: #ffffff;

//FONTS
$main-font: 'EB Garamond', serif;

//MEDIAQUERIES
$tablet: 768px;
$desktop: 1024px;

//FONT SIZE
$font-regular: 16;
$font-small: rem(14);
$font-medium: rem(18);
$font-large: rem(80);
